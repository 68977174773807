.button {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 9px;
    border: 1px solid black;
    border-radius: 4px;
    font-size: 1.2em;
    background-color: black;
    color: white;
    margin-top: 16px;
    cursor: pointer;
    font-weight: bold;
}

.button:hover {
    box-shadow: 0px 0px 1px 1px gray;
}