
.ShowCase {
    background: #F7F7F7;
    width: 100%;
    padding: 16px 0px 32px;
    text-align: center;
}

.ShowCasetitle {
    font-size: 2.4em;
    margin-bottom: 8px;
    margin-top: -4px;
    font-weight: bold;
}