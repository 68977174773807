
.BottomBar {
    width: 100%;
    background-color: #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.BottomBartext {
    font-size: 1.6em;
    margin: 0 8px;
}

.BottomBarimg {
    height: 30px;
    margin-top: 7px;
    margin-bottom: 4px;
    cursor: pointer;
}

.BottomBarimg:hover {
    height: 32px;
    margin-top: 6px;
    margin-bottom: 3px;
    transform: rotate(360deg);
    transition: all 0.5s ease;
}

@media only screen and (max-width: 768px) {
    .BottomBar {
        justify-content: space-between;
    }
}