
.Messages {
    width: 90%;
    max-width: 384px;
    margin: 24px auto;
    min-height: calc(100vh - 180px);
}

.Messagesmessage {
    width: 100%;
    margin-bottom: 2px;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
}

.Messagesname {
    font-size: 2.4em;
    margin: 8px 8px;
    font-weight: bold;
}

.Messagesemail {
    font-size: 2em;
    margin: 0px 8px;
}

.Messagesbody {
    font-size: 1.6em;
    margin: 0px 8px;
}

.Messagesdate {
    font-size: 1.2em;
    margin: 0px 8px 8px;
}