
.About {
    width: 100%;
    min-height: calc(100vh - 130px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
}

.About > div {
    width: 100%;
    max-width: 768px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;
}

.About > div > img {
    align-self: center;
    width: 90%;
    max-width: 300px;
}

.About > div > p {
    font-size: 2.4em;
    margin-top: 16px;
    font-weight: bold;
}

.About > div > span {
    font-size: 1.6em;
    margin-top: 16px;
    width: 90%;
}



